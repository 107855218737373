import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@services/dialog.service';
import { MessageModalComponent } from '@shared/components/message-modal/message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialogService: DialogService) {}

  /**
   * @deprecated Use DialogService with MessageModalComponent
   */
  alert(message: string) {
    const modalOptions: NgbModalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    };
    this.dialogService.dismissAllModals();
    const modal = this.dialogService.openModal(MessageModalComponent, modalOptions);
    modal.componentInstance.message = message;
  }
}
